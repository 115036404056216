.actions-container{
  margin: 8px;
  padding: 24px;
  border: 1px solid #d3d3d3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: scroll;
}

.action-header{
  margin: 0px;
  color:#2b2b2b;
  flex: 0 0;

}

.action-body{
  margin-top: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.add-stock-controller-item-title{
  margin: 0;
}

.switch-title{
  margin-right: 10px;
}

.add-stock-controller-item{
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
}

.add-stock-table{
  width: 100%;
  margin-top: 10px;
}

.add-stock-row{
  display: flex;
    justify-content: space-between;
    padding: 5px 0px;
}

.add-stock-btn{
  width: 100%;
  margin-top: 24px;
}

.add-stock-title{
  flex: 0 0;
}

.add-stock-switch{
  flex: 0 0;
}

.add-stock-item{
  flex: 1 0;
}

.input-item{
  width: 100%;
}

.postback-container{
  background: red;
  height: 100%;
  padding: 25px
}

.limit-order-collapse {
  border: none;
}

.limit-order-collapse .ant-collapse-item {
  border: none;
}

.limit-order-collapse .ant-collapse-header {
  padding-left: 0;
  border: none;
  background: white;
  font-style: italic;
}

.limit-order-collapse .ant-collapse-content {
  border: none;
}

@media screen and (max-width: 480px) {
  .add-stock-info-btn{
    display: none;
  }

  #postback-section {
    order: 1;
    margin-top: 16px;
  }
}
