.postback-info-container{
  width: 65%;
  height: 450px;
}

.postback-info-container-item{
  border-bottom: 1px solid #dddddd;
  transition: all 0.3s ease-out;
  cursor: pointer;
}

.showOnHover{
  opacity: 0;
  color: #ababab;
  transition: opacity 0.3s ease-out;
}

.postback-info-container-item:hover{
  background: #fff;
}
.postback-info-container-item:hover .showOnHover{
  opacity: 1;
}

.table-container{
  padding: 30px 20px;
  border-radius: 4px;
  background: #f3f3f3;
}

.postback-info-container > h3 h2{
  flex: 1 0
}

.mt40{
  margin-top: 40px;
}

.mb20{
  margin-bottom: 20px;
}

td th {
  margin: 5px 0px;
}
