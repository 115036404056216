.stats{
    display: inline;
}

.inner-headers{
    margin: 10px 0px 10px 0px;
}

.statusHeaders{
    margin-bottom: 10px;
}
.settings-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
