.news-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  justify-content: space-between;
  grid-gap: 32px;
}
@media screen and (max-width: 480px) {
  .news-container {
    justify-content: space-around;
  }
}
.news-card {
  width: 300px;
}

.news-header-img {
  width: 300px;
  height: 200px;
  object-fit: cover;
}

.news-summary {
  margin-top: 4px;
}
