.smallplug-container {
    height: 100vh;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.smallplug-btn {
    margin-top: 16px;
}

.params-input {
    margin-top: 10px;
}