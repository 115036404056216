body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.nav-link {
  color: #ffffff;
  font-weight: 600;
  font-size: 20px;
}

.container {
  min-height: 100vh;
}

.flex-center {
  display: flex;
  justify-content: space-around;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-apart {
  display: flex;
  justify-content: space-between;
}

.mt8 {
  margin-top: 16px;
  justify-self: flex-end;
}

.ml16 {
  margin-left: 16px;
}

.mr8 {
  margin-right: 8px;
}

.ml8 {
  margin-left: 8px;
}

.ios-click {
  cursor: pointer;
}

.logo {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.full-width {
  width: 95%;
  margin: 50px 2.5%;
}

.fw {
  width: 100%;
}

.auto-complete {
  width: 100%;
}

.m-lr-8 {
  margin-left: 8px;
  margin-right: 8px;
}

.text-14 {
  font-size: 14px;
}

.response-data-modal {
  max-width: 90vw;
}

.response-data-content {
  white-space: pre;
  overflow: auto;
  max-height: 60vh;
}

@media screen and (min-width: 768px) {
  .auto-complete {
    width: 95%;
  }
  .container {
    padding: 20px;
  }
  .response-data-modal {
    max-width: 60vw;
  }
}
@media screen and (max-width: 480px) {
  .container {
    width: 100%;
    margin: initial auto;
  }

  .xs-mt-15 {
    margin-top: 15px;
  }
}


