.mb12 {
  margin-bottom: 12px;
}

.m8 {
  margin: 8px;
}

.btn {
  width: 100%;
  margin-top: 24px;
}

.actions-container{
  margin: 8px;
  padding: 24px;
  border: 1px solid #d3d3d3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: scroll;
}

.action-header{
  margin-bottom: 32px;
  color:#2b2b2b;
  flex: 0 0;
}
