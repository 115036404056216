.stats{
    display: inline;
}

.headers{
    margin: 40px 0px 10px 0px;
}

.statusHeaders{
    margin-bottom: 10px;
}

.inputBoxes{
    margin: 10px 25px;
}

.dividerOr{
    display: flex;
    align-items: center;
}

.inputsContainer{
    display: flex;
    width: 90%;
    justify-content: center;
    align-items: start;;
    flex-direction: column;
}

.fetchBtn{
    margin: 10px 25px;   
}

@media screen and (max-width: 600px) {
    .inputsContainer {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    
  }
